<template>
  <div class="page-content" v-loading="loading || detailLoading">
    <div class="hotel-title">
      <span class="icon-brand"></span>
      {{ $route.query.id ? "编辑集团" : "新增集团" }}
    </div>
    <div class="flex">
      <div>
        <div class="page-sub-title">集团信息</div>
        <div class="flex">
          <div class="flex-form flex flex-wrap">
            <div class="form-item">
              <div class="form-label">
                <span class="must">*</span>
                集团名称
              </div>
              <el-input
                class="width100"
                v-model="form.hotelName"
                maxlength="32"
                size="small"
                placeholder="集团名称后续不可修改，请勿重复"
                :disabled="!!form.brandId"
                show-word-limit
              ></el-input>
            </div>
            <div class="form-item">
              <div class="form-label">集团简称</div>
              <el-input
                class="width100"
                v-model="form.hotelShortName"
                maxlength="10"
                show-word-limit
                size="small"
                placeholder="请填写集团简称"
              ></el-input>
            </div>
            <div class="form-item">
              <div class="form-label">
                <span class="must">*</span>
                状态
              </div>
              <el-select
                class="width100"
                v-model="form.status"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in statusOption"
                  :key="item.k"
                  :label="item.v"
                  :value="item.k"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-item">
              <div class="form-label">
                <span class="must">*</span>
                是否测试集团
                <el-tooltip content="用于区分客户正式集团以及内部人员测试集团，新增集团时请仔细选择，后续不允许修改" placement="right">
                  <span class="el-icon-info"></span>
                </el-tooltip>
              </div>
              <el-select class="width100" v-model="form.testFlag" placeholder="请选择" size="small" :disabled="!!form.brandId">
                <el-option label="正式集团" :value="false"></el-option>
                <el-option label="测试集团" :value="true"></el-option>
              </el-select>
            </div>
            <div class="form-item2">
              <div class="form-label">
                <span class="must">*</span>
                关联门店
              </div>
              <div class="item flex flex-align-center flex-between" v-for="(o,i) in relationHotelList" :key="i">
                <div>
                  <span :class="o.productName === '标准版'?'label':'label2'">{{o.productName}}</span>
                  {{o.hotelShortName || o.hotelName}}
                </div>
                <i class="font-size-16 el-icon-error c-a4 cursor-point" @click="deleteId(o)"></i>
              </div>
              <el-button class="width100 c-theme" size="small" @click="openChoose">+ 选择门店</el-button>
            </div>
          </div>
        </div>
        <div class="page-sub-title">联系人信息</div>
        <div class="flex-form flex flex-wrap">
          <div class="form-item">
            <div class="form-label">
              <span class="must">*</span>
              联系人姓名
            </div>
            <el-input
              class="width100"
              v-model="form.contactPerson"
              maxlength="8"
              size="small"
              placeholder="请填写真实姓名，方便他人识别"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="form-label">
              <span class="must">*</span>
              联系人手机号
              <!-- <span class="font-size-12 c-a4 "
                >(开户后向该手机号发送开户通知短信)</span
              > -->
            </div>
            <el-input
              class="width100"
              v-model="form.contactPhone"
              size="small"
              placeholder="请填写有效手机号"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="form-label">
              <span class="must">*</span>
              初始账号
            </div>
            <el-input
              class="width100"
              v-model="form.accountUsername"
              size="small"
              placeholder="用以登录集团系统"
              :disabled="!!form.brandId"
            ></el-input>
          </div>
          <div class="form-item" v-if="!form.brandId">
            <div class="form-label">
              <span class="must">*</span>
              初始密码
            </div>
            <el-input
              class="width100"
              v-model="form.pwd"
              size="small"
              placeholder="返回后将不再显示，请牢记密码"
              disabled
            ></el-input>
          </div>
        </div>
        <div class="page-sub-title">系统信息</div>
        <div class="flex-form flex flex-wrap">
          <div class="form-item">
            <div class="form-label">
              <span class="must">*</span>
              开始日期
            </div>
            <el-date-picker
              class="width100"
              v-model="form.enableTime"
              type="date"
              size="small"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions0"
              placeholder="选择开始日期">
            </el-date-picker>
          </div>
          <div class="form-item">
            <div class="form-label">
              <span class="must">*</span>
              到期日期
            </div>
            <el-date-picker
              class="width100"
              v-model="form.expireTime"
              type="date"
              size="small"
              value-format="yyyy-MM-dd"
              disabled
              placeholder="选择到期日期">
            </el-date-picker>
          </div>
        </div>
        <div class="flex-form flex flex-wrap">
          <div class="text-center m-t-16 width100">
            <el-button size="small" @click="goback">取消</el-button>
            <el-button
              type="primary"
              size="small"
              @click="confirm"
              class="m-l-90"
              >保存</el-button
            >
          </div>
        </div>
      </div>
      <div class="text-center">
        <div class="form-label">集团Logo</div>
        <img v-if="form.hotelLogo" class="upload-img" :src="form.hotelLogo" />
        <div v-else class="upload-img-empty"></div>
        <div class="upload-tips">推荐尺寸：40*40px</div>
        <upload
          :urls="form.hotelLogo ? [form.hotelLogo] : []"
          @change="handleImageChange"
          :showFileList="false"
        >
          <el-button class="border-btn" plain size="small">
            <i class="el-icon-upload2"></i>
            上传Logo
          </el-button>
        </upload>
      </div>
    </div>
    <addHotelList ref="addHotelList" :visible="addHotelListVisible" :hotelIds="addList" @close="addHotelListVisible = false" @choose="choose" @deleteId="deleteId"></addHotelList>
  </div>
</template>

<script>
import moment from 'moment'
import { bread_addBrand, edit_brand, bread_brandUpdateBiz } from "@/api/hotel";
// import { get_user_list } from '@/api/user'
import { get_key_value } from "@/api/common";
import { FormValid } from "@/utils/formValid";
import { MathRand } from "@/utils/uuid";
import upload from "@/components/upload";

import addHotelList from "./component/addHotelList"

const form = {
  brandId: "",
  hotelName: "",
  hotelShortName: "",
  status: "2", // 默认新开户
  hotelLogo: "",
  contactPerson: "",
  contactPhone: "",
  accountUsername: "",
  pwd: "",
  enableTime: "",
  expireTime: "",
  relationHotelIdList: [],
  testFlag: false
};
export default {
  name: "group-edit",
  components: {
    upload,
    addHotelList
  },
  data() {
    return {
      form: this.$deepClone(form),
      formOld: {},
      statusOption: [],
      selectLoading: false,
      loading: false,
      detailLoading: true,
      poiId: "",
      brandAddHotelList: [],
      addHotelListVisible: false,
      relationHotelList: [],
      addList: []
    };
  },
  computed: {
    checkFormList() {
      let arr = [
        {
          label: "集团名称",
          value: "hotelName",
          methods: ["notEmpty"],
        },
        {
          label: "关联门店",
          value: "relationHotelIdList",
          methods: ["notEmpty"],
        },
        {
          label: "状态",
          value: "status",
          methods: ["notEmpty"],
        },
        {
          label: "联系人姓名",
          value: "contactPerson",
          methods: ["notEmpty"],
        },
        {
          label: "联系人手机号",
          value: "contactPhone",
          methods: ["notEmpty", "isPhone"],
        },
        {
          label: "初始账号",
          value: "accountUsername",
          methods: ["notEmpty"],
        },
        {
          label: "开始时间",
          value: "enableTime",
          methods: ["notEmpty"],
        },
        {
          label: "到期日期",
          value: "expireTime",
          methods: ["notEmpty"],
        },
      ];
      if (!this.form.brandId) {
        arr = arr.concat([
          {
            label: "初始密码",
            value: "pwd",
            methods: ["notEmpty"],
          },
        ]);
      }
      return arr;
    },
    pickerOptions0() {
      return {
        disabledDate: date => {
          return moment(date).isBefore(moment(), 'day')
        }
      }
    },
  },
  created() {
    this.detailLoading = true;
  },
  async mounted() {
    await this.getOption();
  },
  methods: {
    goback() {
      const keepAlive = this.$store.state.page.keepAlive.concat(["hotel-list"]);
      this.$store.commit("page/SET_KEEPALIVE", keepAlive);
      this.$router.go(-1);
    },
    gobackwithReset() {
      this.$store.commit("page/SET_KEEPALIVE", []);
      this.$router.go(-1);
    },
    getOption() {
      get_key_value({
        type: "HOTEL_STATUS",
      }).then((res) => {
        this.statusOption = res ? res.records : [];
        if (this.$route.query.id) {
          console.log(this.$route.query.form)
          this.form = JSON.parse(this.$route.query.form)
          this.form.brandId = this.$route.query.id;
          this.form.status = this.form.status + "";
          this.relationHotelList = this.form.hotelInfoList
          this.form.relationHotelIdList = this.form.hotelInfoList.map( o => {
            return o.id
          })
          console.log(this.form,'form')
          this.detailLoading = false
          // this.formOld = this.$deepClone(this.form);
          // this.detailLoading = false;
          // await this.getDetail();
        } else {
          this.form.pwd = MathRand();
          this.form.brandId = null;
          this.detailLoading = false;
        }
      });
    },
    setExpireTime(){
      let lastDate = this.relationHotelList.reduce((latest,obj)=>{
        if(!latest || new Date(obj.expireTime) > new Date(latest)){
          return obj.expireTime
        }else{
          return latest
        }
      },null)
      this.form.expireTime = lastDate ? lastDate : ''
    },
    choose(val) {
      const form = this.$route.query.form || '{}'
      this.form.relationHotelIdList = (JSON.parse(form).hotelInfoList || []).map(o => {
        return o.id
      })
      this.relationHotelList = JSON.parse(form).hotelInfoList || []
      this.addList = val
      val.map(o => {
        this.form.relationHotelIdList.push(o.id)
        this.relationHotelList.push(o)
      })
      this.setExpireTime()
    },
    deleteId(val) {
      this.form.relationHotelIdList.splice(this.form.relationHotelIdList.findIndex(item => item == val.id), 1)
      this.relationHotelList.splice(this.relationHotelList.findIndex(item => item.id == val.id), 1)
      this.$refs.addHotelList.choose(val)
      this.setExpireTime()
    },
    // getDetail() {
    //   get_hotel_detail({
    //     brandId: this.form.brandId,
    //   })
    //     .then((res) => {
    //       this.form = Object.assign(this.form, res);
    //       this.form.brandId = this.form.id;
    //       this.form.status = this.form.status + "";
    //       this.formOld = this.$deepClone(this.form);
    //       this.detailLoading = false;
    //     })
    //     .catch(() => {
    //       this.detailLoading = false;
    //     });
    // },
    handleImageChange(list) {
      this.form.hotelLogo = list[0];
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value];
        formValid.add(label, v, methods);
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res);
        return false;
      }
      if (this.form.hotelShortName.length > 10) {
        this.$message.warning("集团简称长度不可大于10位");
        return false;
      }
      if (this.form.testFlag === '') {
        this.$message.warning('请选择是否为测试集团')
        return false
      }
      if (
        this.form.accountUsername.length > 32 ||
        this.form.accountUsername.length < 6
      ) {
        this.$message.warning("开户人账号必须为6-32位");
        return false;
      }
      return {
        ...this.form,
      };
    },
    async checkBrand() {
      const res = await bread_brandUpdateBiz({
        brandId: this.form.brandId,
        relationHotelIdList: this.form.relationHotelIdList
      })
      return res.unbindUserLoginOtherCount || 0
    },
    async confirm() {
      let params = this.checkForm();
      console.log(params,'params')
      if (!params) return;
      console.log(params);
      if (this.form.brandId) {
        const unbindUserLoginOtherCount = await this.checkBrand()
        if (unbindUserLoginOtherCount > 0) {
          const confirm = await this.$confirm(`<div class="text-left">被解绑门店中，有用户可登录其他门店，建议登录该门店将用户解绑后重试。</div><div class="text-left grey mt-10">（支持解绑后再登录该门店进行操作）</div>`, '温馨提示',
          {
            confirmButtonText: '确认保存',
            cancelButtonText: '取消',
            customClass: 'dialog-class unbind-dialog-class',
            center: true,
            dangerouslyUseHTMLString: true
          })
          if (!confirm) {
            return
          }
        }
        this.loading = true;
        delete params.expireTime;
        delete params.pwd;
        delete params.accountUsername;
        if (!this.showRemark) {
          delete params.remarkReason;
        }
        edit_brand(params)
          .then(() => {
            this.loading = false;
            this.$message.success("保存成功");
            this.gobackwithReset();
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        const confirm = await this.$confirm('确认开户？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'dialog-class',
          center: true
        })
        if (!confirm) return
        this.loading = true;
        delete params.brandId;
        bread_addBrand(params)
          .then(() => {
            this.loading = false;
            this.$message.success("保存成功");
            this.gobackwithReset();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    openChoose() {
      this.addHotelListVisible = true
    }
  },
};
</script>

<style lang="scss" scoped>
.icon-brand {
  background: url("../../assets/icons/icon-brand.png") no-repeat center center;
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: -1px;
  margin-right: 8px;
}
.hotel-title {
  color: #5390ff;
  font-size: 18px;
  margin-bottom: 20px;
}
.flex-form {
  width: 800px;
}
.form-item {
  margin-bottom: 16px;
  width: 45%;
  margin-right: 5%;
  .must {
    color: #e14b38;
  }
}
.form-item2 {
  margin-bottom: 16px;
  width: 100%;
  margin-right: 5%;
  .must {
    color: #e14b38;
  }
}
.form-label {
  padding-bottom: 10px;
  text-align: left;
}
.upload-img {
  width: 200px;
  height: 200px;
  display: block;
  margin-bottom: 10px;
}
.upload-img-empty {
  background: #f6f8fc url("../../assets/icons/icon-upload-b.png") no-repeat
    center center/126px 116px;
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}
.upload-tips {
  color: #c2c9d6;
  margin-bottom: 10px;
}
.item {
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  .label {
    // background: #5390FF;
    background: rgba(83,144,255,0.1);
    // opacity: 0.1;
    color: #5181D9;
    padding: 4px 10px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 13px;
  }
  .label2 {
    // background: #5390FF;
    background: #FBF2DF;
    // opacity: 0.1;
    color: #C9920D;
    padding: 4px 10px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 13px;
  }
}
.width100 {
  width: 100%;
}
.m-l-90 {
  margin-left: 90px;
}
.c-a4 {
  color: #a4a4a4;
}
</style>
<style lang="scss">
.unbind-dialog-class{
  .el-message-box__btns{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    .el-button{
      margin-left: 54px !important;
      &.el-button--primary{
        margin-left: 0 !important;
      }
    }
  }
}
.icon-warning-outline{
  background: url('../../assets/icons/icon-warning-outline.png');
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: -1px;
}
</style>
