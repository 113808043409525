export class FormValid {
  constructor() {
    this.validList =  [];
  }
  notEmpty(name, value) {
    const msg = name + '不能为空';
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return msg;
      }
      const emptyValues = ['', null, undefined];
      const res = value.every(v => emptyValues.includes(v));
      if (res) {
        return msg;
      }
    }
    if ((value !== 0) && (value !== false) && (!value)) {
      return msg;
    }
    return true;
  }
  isNumber(name, value) {
    const msg = name + '必须为数字'
    if (isNaN(value)) {
      return msg;
    }
    return true;
  }
  isInt(name, value) {
    const msg = name + '必须为正整数'
    const int = Math.floor(value) === (+value) && value > 0;
    if (!int) {
      return msg;
    }
    return true;
  }
  isPhone(name, value) {
    const msg = name + '不是有效手机号'
    const phone = /^1[3456789]\d{9}$/
    if (!phone.test(value)) {
      return msg;
    }
    return true;
  }
  add(name, value, methods) {
    for (const m of methods) {
      this.validList.push(this[m].bind(this, name, value))
    }
  }
  start() {
    for (const m of this.validList) {
      const res = m();
      if (res !== true) {
        return res;
      }
    }
    return true;
  }
}
