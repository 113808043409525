export const uuid = function (len, radix, type) {
  const number = '0123456789'.split('');
  const a = 'abcdefghijklmnopqrstuvwxyz'.split('');
  const A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
	var chars = number.concat(a, A)
	var uuid = [], i;
  radix = radix || chars.length;
  if (type == 'pwd') {
    // 随机产生8位包含大写字母、小写字母、数字中的三项的密码
    for (i = 0; i < 8; i++) {
      if (i < 3) uuid.push(number[0 | Math.random() * number.length])
      else if (i < 6) uuid.push(a[0 | Math.random() * a.length])
      else uuid.push(A[0 | Math.random() * A.length])
    }
    uuid = shuffle(uuid)
  } else if (len) {
    for (i = 0; i < len; i++) {
      uuid[i] = chars[0 | Math.random() * radix]
    }
	} else {
		var r;
		uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
		uuid[14] = '4';
		for (i = 0; i < 36; i++) {
			if (!uuid[i]) {
				r = 0 | Math.random() * 16;
				uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
			}
		}
	}
	return uuid.join('');
}

export const shuffle = function(v){
  for(var j, x, i = v.length; i; j = parseInt(Math.random() * i), x = v[--i], v[i] = v[j], v[j] = x);
  return v;
};

export const MathRand = function(){
  var Num = ""
  for (var i = 0; i < 6; i++) {
    Num += Math.floor(Math.random() * 10);
  }
  return Num
}