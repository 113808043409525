<template>
  <el-dialog
    title="选择门店"
    :visible.sync="visible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div slot="title">
      选择门店
      <span class="c-a4 font-size-12">（已选{{selected.length}}家）</span>
    </div>
    <div class='box'>
      <div class="search flex flex-align-center">
        <el-input class="mr-10" v-model="searchHotel" size="small" clearable placeholder="请输入门店名称搜索"></el-input>
        <el-button style="width: 100px" type="primary" size="small" placeholder="请输入" @click="getList">查询</el-button>
      </div>
      <div class="list mt-20" v-if="list.length > 0">
        <div class="item flex flex-align-center flex-between" v-for="(o,i) in list" :key="i" @click="choose(o)">
          <div>
            <span :class="o.productName === '标准版'?'label':'label2'">{{o.productName}}</span>
            <span class="c-1" :class="selected.filter(item => {return item.id == o.id}).length>0?'bb-1':''">{{o.hotelShortName || o.hotelName}}</span>
          </div>
          <span :class="selected.filter(item => {return item.id == o.id}).length>0?'icon-check':'icon-uncheck'"></span>
          <!-- <i :class="hotelIds.includes(o.id)?'el-icon-success c-theme font-size-16':'el-icon-success c-a4 font-size-16'"></i> -->
        </div>
      </div>
      <div class="list mt-20" v-else>
        <el-empty :image="require('../../../assets/icons/empty.png')" description="暂无搜索结果"></el-empty>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { bread_brandAddHotelList } from "@/api/hotel";
export default {
  name: '',
  props: {
    visible: {
      type: Boolean
    },
    hotelIds: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
  data() {
    return {
      list: [],
      searchHotel: '',
      selected: []
    };
  },
  watch: {
    visible(val) {
      console.log(this.hotelIds,'this.hotelIds')
      if (val) {
        this.pageParams = {
          pageNo: 1,
          size: 999
        }
        this.getList()
        this.selected = JSON.parse(JSON.stringify(this.hotelIds))
      } else {
        this.list = []
      }
    },
  },
  methods: {
    getList() {
      bread_brandAddHotelList({
        search: this.searchHotel,
        page: 1,
        size: 999
      }).then(res => {
        this.list = res.records || []
      })
    },
    choose(val) {
      console.log(val,11)
      if(this.selected.filter(item => {return item.id == val.id}).length == 0) {
        console.log('choose')
        //  this.$emit('choose',val)
         this.selected.push(val)
      } else {
        console.log('delete')
        // this.$emit('deleteId',val)
        this.selected.splice(this.selected.findIndex(item => item.id == val.id), 1)
      }
      this.$forceUpdate()
      // console.log(this.hotelIds,'hotelids')
    },
    confirm() {
      console.log(this.selected)
      this.$emit('choose',this.selected)
      this.hideDialog()
    },
    hideDialog() {
      this.$emit('close')
    },
  },
};
</script>

<style scoped lang='scss'>
.item {
  border-bottom: 1px solid #f1f1f1;
  // border-radius: 5px;
  padding: 14px 10px;
  margin-bottom: 10px;
  .label {
    // background: #5390FF;
    background: rgba(83,144,255,0.1);
    // opacity: 0.1;
    color: #5181D9;
    padding: 4px 10px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 13px;
  }
  .label2 {
    // background: #5390FF;
    background: #FBF2DF;
    // opacity: 0.1;
    color: #C9920D;
    padding: 4px 10px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 13px;
  }
}
.bb-1 {
  // border: 1px solid #5390FF;
  // font-weight: bold;
  color: #212121!important;
}
.c-a4 {
  color: #a4a4a4;
}
.c-theme {
  color: #5390FF;
}
.c-1 {
  color: #666666;
}
.icon-check {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../../../assets/icons/check.png') no-repeat center center/100% 100%;
}
.icon-uncheck {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../../../assets/icons/uncheck.png') no-repeat center center/100% 100%;
}
</style>
